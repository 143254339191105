<template>
    <div>
        <div class="row no-gutters">
            <div class="col">
                <el-button type="primary" @click="showUserRiskDialog(null)">添加</el-button>
            </div>
        </div>
        <el-table :data="userRisks" class="mt-2">
            <el-table-column prop="hasRiskName" label="是否有风险" width="100" />
            <el-table-column prop="riskTypeName" label="风险类型" width="200" />
            <el-table-column prop="remark" label="备注" />
            <el-table-column label="操作" fixed="right" width="100">
                <template #default="scope">
                    <el-button type="text" @click="showUserRiskDialog(scope.row)">修改</el-button>
                    <el-button type="text" class="text-danger" @click="deleteUserRisk(scope.row.id)">
                        删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog :visible.sync="userRiskDialogVisible" title="用户风险预警" :close-on-click-modal="false" append-to-body
            width="640px">
            <el-form>
                <el-row :gutter="8">
                    <el-col :span="12">
                        <el-form-item label="是否有风险" class="form-item-required">
                            <el-radio-group v-model="userRisk.hasRisk" class="stretch-radio-group">
                                <el-radio-button :label="true">有风险</el-radio-button>
                                <el-radio-button :label="false">无风险</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="风险类型" class="form-item-required">
                            <el-select v-model="userRisk.riskType" placeholder="风险类型">
                                <el-option v-for="userRiskType in $store.state.dataDefinitions.userRiskTypes"
                                    :key="userRiskType.key" :value="userRiskType.key" :label="userRiskType.value" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

                <div class="my-2">
                    <div>附件</div>
                    <div class="border rounded mt-2 p-1">
                        <multi-file-uploader v-model="userRisk.attachments" />
                    </div>
                </div>

                <el-form-item label="备注">
                    <el-input v-model="userRisk.remark" type="textarea" :rows="5" placeholder="备注" />
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button type="primary" @click="saveUserRisk">确定</el-button>
                <el-button @click="userRiskDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        props: {
            registeredUserId: { type: String },
        },
        data() {
            return {
                userRisks: [],
                userRisk: {},
                userRiskDialogVisible: false,
            };
        },
        methods: {
            async loadUserRisks() {
                let response = await this.$axios.get('/api/UserRisk/GetUserRisks', { params: { registeredUserId: this.registeredUserId } });
                this.userRisks = response.data;
            },
            showUserRiskDialog(userRisk) {
                if (userRisk) {
                    this.userRisk = JSON.parse(JSON.stringify(userRisk));
                }
                else {
                    this.userRisk = {};
                }
                this.userRiskDialogVisible = true;
            },
            async saveUserRisk() {
                if (this.userRisk.id == undefined) {
                    await this.$axios.post('/api/UserRisk/AddUserRisk', {
                        registeredUserId: this.registeredUserId,
                        hasRisk: this.userRisk.hasRisk,
                        riskType: this.userRisk.riskType,
                        attachments: this.userRisk.attachments,
                        remark: this.userRisk.remark,
                    });
                    this.userRiskDialogVisible = false;
                    this.loadUserRisks();
                }
                else {
                    await this.$axios.post('/api/UserRisk/UpdateUserRisk', {
                        id: this.userRisk.id,
                        registeredUserId: this.registeredUserId,
                        hasRisk: this.userRisk.hasRisk,
                        riskType: this.userRisk.riskType,
                        attachments: this.userRisk.attachments,
                        remark: this.userRisk.remark,
                    });
                    this.userRiskDialogVisible = false;
                    this.loadUserRisks();
                }
            },
            async deleteUserRisk(id) {
                if (!confirm('确定要删除吗？')) {
                    return;
                }

                await this.$axios.post('/api/UserRisk/DeleteUserRisk', { id: id });
                this.loadUserRisks();
            },
        },
        created() {
            this.loadUserRisks();
        },
    };
</script>